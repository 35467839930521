<template>
  <v-container id="products" class="ma-auto pa-0" fluid>
    <v-row class="pa-0 ma-0 main-modified">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="pa-0 ma-0" :style="{'background-image': 'url(' + storage_url+path+'products-bg.jpg' + ')'}" style="height:300px;filter: grayscale(0.1);background-repeat: no-repeat;background-size: cover;">
          <v-col class="text-center ma-auto">
            <h1 class="text-h4 custom-white--text text-uppercase" style="-webkit-text-stroke: 0.4px black;">{{
              $t("products")
            }}</h1>
          </v-col>
        </v-row>
        <transition name="fade" mode="out-in" v-if="product_loading">
          <v-row class="pa-8 ma-0 mt-8" justify="center">
            <v-col
              v-for="i in itemsperpage"
              :key="i"
              cols="12"
              sm="10"
              md="6"
              lg="4"
            >
              <v-skeleton-loader
                type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="products.length==0 && !product_loading">
          <v-row class="text-center pa-12 ma-auto my-7" ><i class="ma-auto text-h4 text--secondary">{{$t("noresult")}}.</i></v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="products.length && !product_loading">
          <v-row class="pa-8 ma-0 mt-8" justify="center" :key="itemsperpage * page">
            <v-col v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('products.create')" cols="12">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn block height="60" x-large v-bind="attrs" v-on="on" @click="OpenCreateProduct()" text outlined :title="$t('create')"><v-icon class="text--secondary" large>mdi-plus-box</v-icon></v-btn>
                </template>
                <span>{{$t('create')}}</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-for="(item, i) in products.slice(
                itemsperpage * (page - 1),
                itemsperpage * page
              )"
              :key="i"
              cols="12"
              sm="10"
              md="6"
              lg="4"
            >
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                    class="elevation-0"
                    outlined
                    @click="ViewProduct(item.id)"
                    :dark="hover"                    
                    :class="{'custom-accent lighten-1 ' : hover}"
                  >
                    <v-list-item three-line>
                      <v-list-item-avatar class="ma-3" size="120" tile>
                        <v-img v-if="item.image" :src="storage_url+path+item.image" :alt="item.alt">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="custom-secondary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <v-img v-else :src="storage_url+no_image_path+'noimage.png'" :alt="$t('productsdefaultbgalt')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="custom-secondary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="text-h5">
                          <span class="d-inline">{{$i18n.locale == "en" ? item.titleEN : item.titleFR}}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="ma-0 pa-0"><span class="" v-html="$i18n.locale == 'en'? item.descriptionEN: item.descriptionFR"></span></v-list-item-subtitle>
                      </v-list-item-content>
                      <transition name="fade-right" mode="in-out">
                        <v-list-item-avatar v-if="hover" class="ml-auto my-auto" size="80" tile>
                          <v-icon class="text-h4">mdi-arrow-right</v-icon>
                        </v-list-item-avatar>
                      </transition>
                      <v-list-item-action v-if="user && isLoggedIn">
                        <v-tooltip bottom v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('products.update')">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenUpdateProduct(item)" :title="$t('update')">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>{{$t('update')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('products.delete')">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenDeleteProduct(item)" :title="$t('delete')">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>{{$t('delete')}}</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
          </v-row>
        </transition>
        <v-row class="pa-0 ma-0">
          <v-col v-if="Math.ceil(products.length / itemsperpage)>1">
            <v-pagination
              v-model="page"
              :length="Math.ceil(products.length / itemsperpage)"
              color="custom-accent"
              :total-visible="7"
              circle
            ></v-pagination>
          </v-col>
        </v-row>

        <!-- view dialog -->
        <v-dialog v-model="productdetailsoverlay" width="1900px" scrollable>
          <v-card class="overflow-hidden" v-if="productdetailsoverlay">
            <v-card-title class="pa-0 ma-0 pa-4 mr-6">
              <span class="text-h5 custom-accent--text">{{$i18n.locale == "en" ? activeproduct.titleEN : activeproduct.titleFR}}</span>
              <v-tooltip left color="custom-secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    absolute
                    top
                    right
                    style="z-index: 3;"
                    v-bind="attrs"
                    v-on="on"
                    :title="$t('close')"
                    @click="productdetailsoverlay = false"
                    icon
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("close") }}</span>
              </v-tooltip>
            </v-card-title>
            <v-divider class="my-4"></v-divider>
            <v-card-text>
              <v-row class="pa-0 ma-0">
                <v-col cols="12" sm="auto">
                  <v-img v-if="activeproduct.image" class="ma-auto" width="130" :src="storage_url+path+activeproduct.image" :alt="activeproduct.alt">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="custom-secondary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-img v-else class="ma-auto" width="130" :src="storage_url+no_image_path+'noimage.png'" :alt="$t('productsdefaultbgalt')">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="custom-secondary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" sm="">
                  <h1 class="title">{{$i18n.locale=='en'?activeproduct.subtitleEN:activeproduct.subtitleFR}}</h1>
                  <span class="caption" v-html="$i18n.locale=='en'?activeproduct.descriptionEN:activeproduct.descriptionFR"></span>
                </v-col>
              </v-row>
              <product-attachments :activeproduct="activeproduct"></product-attachments>
              <v-divider class="my-4"></v-divider>
              <product-services :activeproduct="activeproduct"></product-services>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- view dialog -->

        <!-- create dialog -->
        <v-dialog v-model="productcreateoverlay" width="800px">
          <v-card v-if="productcreateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("additem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <file-input-drop @upload="UploadFiles" :allowedTypes="allowedTypes" :allowedSize="allowedSize" :allowedLength="allowedLength" :multiple="multiple"></file-input-drop>
              <create-product @isValid="validate" :NewItem="activeproduct"></create-product>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="productcreateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn dark depressed :title="$t('clear')" @click="ClearProduct()">{{$t('clear')}}</v-btn>
              <v-btn class="success" :title="$t('create')" depressed :loading="product_loading || file_loading" :disabled="!productcreateformvalid" @click="CreateProduct()">
                <span>{{$t('create')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- create dialog -->

        <!-- update dialog -->
        <v-dialog v-model="productupdateoverlay" width="800px">
          <v-card v-if="productupdateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("updateitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
               <file-input-drop v-if="!activeproduct.image" @upload="UploadFiles" :allowedTypes="allowedTypes" :allowedSize="allowedSize" :allowedLength="allowedLength" :multiple="multiple"></file-input-drop>
              <update-product @isValid="validate" @deleteFile="OpenDeleteFile" :UpdatedItem="activeproduct"></update-product>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="productupdateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="success" :title="$t('update')" :loading="product_loading" depressed :disabled="!productupdateformvalid" @click="UpdateProduct()">
                <span>{{$t('update')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- update dialog -->

        <!-- delete dialog -->
        <v-dialog v-model="productdeleteoverlay" width="500px">
          <v-card>
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("deleteitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="productdeleteoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="error" :title="$t('delete')" :loading="product_loading" depressed @click="DeleteProduct()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- delete dialog -->

        <!-- file delete dialog -->
        <v-dialog v-model="filedeleteoverlay" width="500px">
          <v-card>
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("deleteitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="filedeleteoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="error" :title="$t('delete')" :loading="file_loading" depressed @click="DeleteFile()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- file delete dialog -->

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import createproduct from '../../components/Product/CreateProduct.vue';
import updateproduct from '../../components/Product/UpdateProduct.vue';
import fileinputdrop from "../../components/Admin/FileInputDrop.vue";
import productattachments from "../../components/ProductAttachment/ProductAttachments.vue";
import productservices from "../../components/ProductService/ProductServices.vue";
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "Products",

  data() {
    return {
      productcreateoverlay: false,
      productupdateoverlay: false,
      productdeleteoverlay: false,
      productcreateformvalid: false,
      productupdateformvalid: false,

      page: 1,
      itemsperpage: 3,
      productdetailsoverlay: false,
      activeproduct: {},

      allowedTypes: "image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon, image/jpg, image/vnd.microsoft.icon, image/svg+xml",
      allowedSize: "268435456",
      allowedLength: 20,
      multiple:false,
      files:[],
      filedeleteoverlay: false,

      storage_url: process.env.VUE_APP_STORAGEURL,
      path: "solutions/products/images/",
      no_image_path: "default_images/",
    };
  },
  components: {
    'create-product':createproduct,
    'update-product':updateproduct,
    'file-input-drop':fileinputdrop,
    'product-attachments':productattachments,
    'product-services':productservices,
  },
  async created() {
    if(this.$route.params.productid){
      this.ViewProduct(this.$route.params.productid);
    }
  },
  computed: {
    ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    ...mapGetters('product', {products:'getProducts'}),
    ...mapState("product", {product_loading: "loading"}),
    ...mapState("file", {file_loading: "loading"}),
  },
  watch: {
    $route() {
      if(this.$route.params.productid){
        this.ViewProduct(this.$route.params.productid);
      }
    },
    page(){
      this.$root.$emit('toTop');
    },
  },
  methods: {
    validate(context,isValid){
      switch (context) {
        case 'create':
            this.productcreateformvalid=isValid;
            break;

        case 'update':
            this.productupdateformvalid=isValid;
            break;

        default:
            break;
      }
    },
    OpenCreateProduct() {
      this.productcreateoverlay = true;
      this.activeproduct = {};
    },
    OpenUpdateProduct(item) {
      this.productupdateoverlay = true;
      this.activeproduct = {...item};
    },
    OpenDeleteProduct(item) {
      this.productdeleteoverlay = true;
      this.activeproduct = {...item};
    },
    OpenDeleteFile() {
      this.filedeleteoverlay = true;
    },
    ViewProduct(id) {
      let product = this.products.filter(product=>{
        return product.id==id;
      })[0];
      this.productdetailsoverlay = true;
      this.activeproduct = {...product};
    },
    CreateProduct() {
      if(this.productcreateformvalid && this.user && this.user.permission.map(i=>i.name).includes('products.create')){
        if(this.activeproduct.image){
          const formData = new FormData();
          formData.append("path", this.path);
          for (let index = 0; index < this.files.length; index++) {
            let file = this.files[index];
            formData.append("data[" + index + "]", file);
          }
          this.addFile(formData).then((response)=>{
            if(!this.multiple)
              this.activeproduct.image = response.data[0].split(';')[0];
            this.addProduct(this.activeproduct).then(() => {
                this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
                this.productcreateoverlay = false;
                this.activeproduct = {};
              },() => {
                this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
              }
            );
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          });
        }
        else{
          this.addProduct(this.activeproduct).then(() => {
              this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
              this.productcreateoverlay = false;
              this.activeproduct = {};
            },() => {
              this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
            }
          );
        }
        
      }
    },
    UpdateProduct() {
      if(this.productupdateformvalid && this.user && this.user.permission.map(i=>i.name).includes('products.update')){
        if(this.activeproduct.image && !this.products.find(x => x.id === this.activeproduct.id).image){
          const formData = new FormData();
          formData.append("path", this.path);
          for (let index = 0; index < this.files.length; index++) {
            let file = this.files[index];
            formData.append("data[" + index + "]", file);
          }
          this.addFile(formData).then((response)=>{
            if(!this.multiple)
              this.activeproduct.image = response.data[0].split(';')[0];
            this.updateProduct(this.activeproduct).then((response) => {
                this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
                this.productupdateoverlay = false;
                this.activeproduct = {};
                this.UpdateProducts(response.data);
              },() => {
                this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
              }
            );
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          });
        }
        else{
          this.updateProduct(this.activeproduct).then((response) => {
              this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
              this.productupdateoverlay = false;               
              this.activeproduct = {};
              this.UpdateProducts(response.data);
            },() => {
              this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
            }
          );
        }
      }
    },
    UpdateProducts(payload) {
      const edited_item_index=this.products.findIndex(
          (item) => item.id === payload.id
        );
      if (edited_item_index !== -1)
          this.products[edited_item_index] = payload;
    },
    DeleteProduct() {
      if(this.user && this.user.permission.map(i=>i.name).includes('products.delete')){
        this.deleteProduct(this.activeproduct).then((response) => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
            this.productdeleteoverlay = false;
            this.activeproduct = {};
            if(response.data.image){
              let payload ={name:response.data.image, path:this.path};
              this.deleteFile(payload);
            }
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    DeleteFile() {
      if(this.activeproduct.image){
        let payload ={name:this.activeproduct.image, path:this.path};
        this.deleteFile(payload).then(()=>{
            this.activeproduct.image='';
            this.filedeleteoverlay = false;
            this.updateProduct(this.activeproduct).then((response) => {
                this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
                this.UpdateProducts(response.data);
              },() => {
                this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
              }
            );
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    ClearProduct() {
      this.activeproduct={image:this.activeproduct.image};
    },
    UploadFiles(files) {
      this.files=files;
      if(!this.multiple)
        this.$set(this.activeproduct, 'image', this.files[0]?.name);
    },

    ...mapActions("product", ["addProduct","updateProduct","deleteProduct"]),
    ...mapActions("file", ["addFile","deleteFile"]),
  },
};
</script>
<style scoped>
@import url("../../assets/styles/products.css");
</style>