<template> 
  <v-container  class="ma-auto" fluid>
    <v-form ref="form" v-model="valid" v-if="user && isLoggedIn" lazy-validation @submit.prevent="submit">
        <div class="pa-0 ma-0">
            <v-menu v-model="icon_menu" min-width="auto" :close-on-content-click="false" open-on-click offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="NewItem.icon"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        color="custom-accent darken-4"
                        :label="$t('icon')"
                        :placeholder="$t('icon')"
                        required
                        :counter="maxTextfieldLength"
                        :rules="textfieldRules"
                        :append-icon="NewItem.icon?NewItem.icon:'mdi-border-none-variant'"
                    >
                    </v-text-field>
                </template>
                <icon-picker v-model="NewItem.icon"></icon-picker>
            </v-menu>
            <v-text-field
                v-model="NewItem.titleEN"
                color="custom-accent darken-4"
                :label="$t('titleEN')"
                :placeholder="$t('titleEN')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="NewItem.titleFR"
                color="custom-accent darken-4"
                :label="$t('titleFR')"
                :placeholder="$t('titleFR')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <tiptap-vuetify
                class="mt-4"
                v-model="NewItem.descriptionEN"
                @focus="notFocused=false"
                :extensions="extensions"
                :placeholder="$t('descriptionEN')"
            ></tiptap-vuetify>
            <v-row>
                <v-col cols="8" v-if="!notFocused">
                    <v-input
                        :error-messages="textareaError(NewItem.descriptionEN)"
                        error>
                    </v-input>
                </v-col>
                <v-col cols="auto" class="ml-auto">
                    <v-input
                        :error-messages="textareaCounter(NewItem.descriptionEN)"
                        error
                        :disabled="textareaError(NewItem.descriptionEN)==null || notFocused">
                    </v-input>
                </v-col>
            </v-row>
            <tiptap-vuetify
                class="mt-4"
                v-model="NewItem.descriptionFR"
                @focus="notFocused=false"
                :extensions="extensions"
                :placeholder="$t('descriptionFR')"
            ></tiptap-vuetify>
            <v-row>
                <v-col cols="8" v-if="!notFocused">
                    <v-input
                        :error-messages="textareaError(NewItem.descriptionFR)"
                        error>
                    </v-input>
                </v-col>
                <v-col cols="auto" class="ml-auto">
                    <v-input
                        :error-messages="textareaCounter(NewItem.descriptionFR)"
                        error
                        :disabled="textareaError(NewItem.descriptionFR)==null || notFocused">
                    </v-input>
                </v-col>
            </v-row>
        </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import iconpicker from "../Admin/IconPicker.vue";
import { TiptapVuetify, History, Paragraph, Bold, Italic, Underline, Link, ListItem, BulletList, OrderedList, Heading, Strike, Code, HorizontalRule, HardBreak } from 'tiptap-vuetify';

export default({
    
    props: ['NewItem'],
    data() {
        return {
            valid: false,
            icon_menu: false,
            notFocused: true,
            extensions: [
                History,
                Paragraph,
                Bold,
                Italic,
                Underline,
                Link,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                    levels: [1, 2, 3]
                    }
                }],
                Strike,
                Code,
                HorizontalRule,
                HardBreak
            ],
        }
    },
    components: { 
        TiptapVuetify,
        'icon-picker':iconpicker,
    },
    computed: {
        maxTextfieldLength () {
        return 200;
        },
        maxTextareaLength () {
        return 3500;
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },

        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    },
    watch:{
        NewItem: {
            handler(){
                this.validate();
            }, deep: true 
        },
    },
    methods: {
        textareaError(item){ 
            if(item?.replace(/<\/?[^>]+(>|$)/g, '').length==0 || !item)
            {
                return this.$t('fieldError');
            }
            else if(item?.replace(/<\/?[^>]+(>|$)/g, '').length>this.maxTextareaLength){
                return this.$t('fieldValidError1')+' '+this.maxTextareaLength+' '+this.$t('fieldValidError2');
            }
            return null;
        },
        textareaCounter(item){ 
            if(item?.replace(/<\/?[^>]+(>|$)/g, '').length){
                return item.replace(/<\/?[^>]+(>|$)/g, '').length+'/'+this.maxTextareaLength;
            }
            return '0/'+this.maxTextareaLength;
        },
        validate(){
            let isValid = this.$refs.form.validate() && this.textareaError(this.NewItem.descriptionEN)==null && this.textareaError(this.NewItem.descriptionFR)==null && !this.notFocused;
            this.notFocused=false;
            this.$emit('isValid','create',isValid);
        }
    }
})
</script>
<style scoped>
</style>
