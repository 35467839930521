<template>
    <v-container id="productattachments" class="ma-auto" fluid>
        <v-row class="pa-0 ma-0">
            <v-col v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('productattachments.create')"
                class="pa-3 ma-0"
                cols="12"
                sm="6"
                md="4"
                lg="3">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn block height="80" x-large v-bind="attrs" v-on="on" @click="OpenCreateProductAttachment()" text outlined :title="$t('create')"><v-icon class="text--secondary" large>mdi-plus-box</v-icon></v-btn>
                    </template>
                    <span>{{$t('create')}}</span>
                </v-tooltip>
            </v-col>
            <v-col
                v-for="item in product_attachments.filter(item=>{return item.product==activeproduct.id})"
                :key="item.id"
                class="pa-3 ma-0"
                cols="12"
                sm="6"
                md="4"
                lg="3">
                <v-input style="border:thin solid rgba(0, 0, 0, 0.12);"
                class="ma-0 pa-3"
                :messages="$bytesToSize(item.size)"
                :prepend-icon="item.icon"
                >
                    <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                    <v-progress-circular v-if="file_loading && temp.id==item.id" indeterminate></v-progress-circular>
                    <v-tooltip v-else bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click.stop="DownloadFile(item)" :title="$t('download')">
                                <v-icon>mdi-download</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('download')}}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('productattachments.delete')">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenDeleteProductAttachment(item)" :title="$t('delete')">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('delete')}}</span>
                    </v-tooltip>
                </v-input>
            </v-col>
        </v-row>

        <!-- create dialog -->
        <v-dialog v-model="productattachmentcreateoverlay" width="800px">
          <v-card v-if="productattachmentcreateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("additem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <file-input-drop @upload="UploadFiles" :allowedTypes="allowedTypes" :allowedSize="allowedSize" :allowedLength="allowedLength" :multiple="multiple"></file-input-drop>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="productattachmentcreateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn dark depressed :title="$t('clear')" @click="ClearProductAttachment()">{{$t('clear')}}</v-btn>
              <v-btn class="success" :title="$t('create')" depressed :loading="product_attachment_loading || file_loading" :disabled="!productattachmentcreateformvalid" @click="CreateProductAttachment()">
                <span>{{$t('create')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- create dialog -->

        <!-- delete dialog -->
        <v-dialog v-model="productattachmentdeleteoverlay" width="500px">
          <v-card>
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("deleteitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="productattachmentdeleteoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="error" :title="$t('delete')" :loading="product_attachment_loading" depressed @click="DeleteProductAttachment()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- delete dialog -->
    </v-container>
</template>
<script>
import { mapState,mapActions,mapGetters} from "vuex";
import fileinputdrop from "../Admin/FileInputDrop.vue";

export default({
    name: 'ProductAttachments',
    props: ['activeproduct'],
    data() {
        return { 
            temp: {},
            activeproductattachment: {},
            productattachmentcreateoverlay: false,
            productattachmentdeleteoverlay: false,
            
            allowedTypes: "text/eml, text/css, text/csv, text/html, text/calendar, text/javascript, application/java-archive, application/x-mpegURL, application/docx, application/gzip, application/zip, application/x-7z-compressed, application/rtf, application/epub+zip, application/vnd.rar, application/vnd.debian.binary-package, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-fontobject, application/doc, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/pdf, application/sql",
            allowedSize: "268435456",
            allowedLength: 20,
            multiple:true,
            files:[],

            path: "files/",
        }
    },
    components: { 
        'file-input-drop':fileinputdrop,
    },
    computed: {
        productattachmentcreateformvalid(){
            return this.files.length;
        },
        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
        ...mapGetters('product_attachment', {product_attachments:'getProductAttachments'}),
        ...mapState("product_attachment", {product_attachment_loading: "loading"}),
        ...mapState("file", {file_loading: "loading"}),
    },
    watch:{
        
    },
    methods: {
        DownloadFile(attachment){
            this.temp={...attachment};
            let payload = {name: attachment.name,path:this.path}
            this.downloadFile(payload);
        },
        OpenCreateProductAttachment() {
            this.productattachmentcreateoverlay = true;
        },
        OpenDeleteProductAttachment(item) {
            this.productattachmentdeleteoverlay = true;
            this.activeproductattachment = {...item};
        },  

        CreateProductAttachment() {
            if(this.user && this.files.length && this.user.permission.map(i=>i.name).includes('productattachments.create')){
                const formData = new FormData();
                formData.append("path", this.path);
                for (let index = 0; index < this.files.length; index++) {
                    let file = this.files[index];
                    formData.append("data[" + index + "]", file);
                }
                this.addFile(formData).then((response)=>{
                    if(this.multiple){
                        for (let index = 0; index < response.data.length; index++) {
                            const element = response.data[index].split(';');
                            let payload = {name:element[0],size:element[1],attachment:element[0],product:this.activeproduct.id,icon:'mdi-file'};
                            this.addProductAttachment(payload).then(() => {
                                    this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
                                    if(index==response.data.length-1){
                                        this.productattachmentcreateoverlay = false;
                                        this.files = [];
                                    }
                                },() => {
                                    this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                                }
                            );
                        }
                    }
                    },() => {
                        this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                    }
                );
            }
        },
        DeleteProductAttachment() {
            if(this.user && this.user.permission.map(i=>i.name).includes('productattachments.delete')){
                this.deleteProductAttachment(this.activeproductattachment).then((response) => {
                    this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
                    this.productattachmentdeleteoverlay = false;
                    this.activeproductattachment = {};
                    if(response.data.attachment){
                        let payload ={name:response.data.attachment, path:this.path};
                        this.deleteFile(payload);
                    }
                    },() => {
                    this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                    }
                );
            }
        },
        ClearProductAttachment() {
            this.files=[];
        },
        UploadFiles(files) {
            this.files=files;
        },

        ...mapActions("product_attachment", ["addProductAttachment","deleteProductAttachment"]),
        ...mapActions("file", ["addFile","deleteFile","downloadFile"]),
    }
})
</script>
<style scoped>
</style>
