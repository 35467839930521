var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{ 'custom-gray': _vm.dragover },attrs:{"outlined":""},on:{"drop":function($event){$event.preventDefault();return _vm.onDrop($event)},"dragover":function($event){$event.preventDefault();_vm.dragover = true},"dragenter":function($event){$event.preventDefault();_vm.dragover = true},"dragleave":function($event){$event.preventDefault();_vm.dragover = false},"click":function($event){return _vm.$refs.file.click()}}},[_c('v-card-text',[_c('v-row',{staticClass:"d-flex flex-column",attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-icon',{class:[_vm.dragover ? 'mt-2, mb-6' : 'mt-5'],attrs:{"size":"30"}},[_vm._v(" mdi-cloud-upload ")]),_c('div',{staticClass:"d-flex flex-column text-center"},[_c('span',{},[_vm._v(_vm._s(_vm.$t('dropfile')))]),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t('maxfilesize'))+" "+_vm._s(_vm.$bytesToSize(_vm.allowedSize)))])])],1),(_vm.uploadedFiles.length > 0)?_c('v-virtual-scroll',{attrs:{"items":_vm.uploadedFiles,"max-height":"195","item-height":"65"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.name},[_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"caption ml-3 text--secondary"},[_vm._v(_vm._s(_vm.$bytesToSize(item.size)))])])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","title":_vm.$t('delete')},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(item.name)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])],1)],1),_c('v-divider')]}}],null,false,2098362398)}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","title":_vm.$t('upload')},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.file.click()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-upload")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('upload')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","title":_vm.$t('clear')},on:{"click":function($event){$event.stopPropagation();return _vm.clear()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close-circle")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('clear')))])])],1),_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","accept":_vm.allowedTypes,"multiple":_vm.multiple},on:{"change":_vm.onDrop}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }