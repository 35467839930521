<template>
    <v-container id="productservices" class="ma-auto" fluid>
        <v-row class="pa-0 ma-auto" justify="center">
            <v-col v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('productservices.create')"
            cols="12">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                <v-btn block height="60" x-large v-bind="attrs" v-on="on" @click="OpenCreateProductService()" text outlined :title="$t('create')"><v-icon large>mdi-plus-box</v-icon></v-btn>
                </template>
                <span>{{$t('create')}}</span>
            </v-tooltip>
            </v-col>
            <v-col
                class="ma-auto"
                cols="12"
                v-for="item in product_services.filter(item=>{return item.product==activeproduct.id})"
                :key="item.id"
                sm="6"
                md="4"
            >
                <v-card height="100%" class="values-cards text-center transparent elevation-0">
                    <v-row class="pa-0 ma-0" style="position:absolute;right:0px;top:0px;" v-if="user && isLoggedIn">
                        <v-col class="pa-0 ma-0" cols="12">
                        <v-tooltip right v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('productservices.update')">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenUpdateProductService(item)" :title="$t('update')">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            </template>
                            <span>{{$t('update')}}</span>
                        </v-tooltip>
                        </v-col>
                        <v-col class="pa-0 ma-0" cols="12">
                        <v-tooltip right v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('productservices.delete')">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenDeleteProductService(item)" :title="$t('delete')">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            </template>
                            <span>{{$t('delete')}}</span>
                        </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-card-title>
                        <v-avatar
                        class="ma-auto"
                        style="border: 1px solid !important"
                        size="88"
                        >
                        <v-icon x-large>
                            {{ item.icon }}
                        </v-icon>
                        </v-avatar>
                    </v-card-title>
                    <v-card-title>
                        <expandable-text-line class="overflow-hidden">
                        <span class="ma-auto">{{
                            $i18n.locale == "en" ? item.titleEN : item.titleFR
                        }}</span>
                        </expandable-text-line>
                    </v-card-title>
                    <v-card-text class="font-weight-light custom-secondary--text">
                        <expandable-text-line class="overflow-hidden">
                        <span v-html="$i18n.locale == 'en'? item.descriptionEN: item.descriptionFR"></span>
                        </expandable-text-line>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- create dialog -->
        <v-dialog v-model="productservicecreateoverlay" width="800px">
          <v-card v-if="productservicecreateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("additem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <create-product-service @isValid="validate" :NewItem="activeproductservice"></create-product-service>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="productservicecreateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn dark depressed :title="$t('clear')" @click="ClearProductService()">{{$t('clear')}}</v-btn>
              <v-btn class="success" :title="$t('create')" depressed :loading="product_service_loading" :disabled="!productservicecreateformvalid" @click="CreateProductService()">
                <span>{{$t('create')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- create dialog -->

        <!-- update dialog -->
        <v-dialog v-model="productserviceupdateoverlay" width="800px">
          <v-card v-if="productserviceupdateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("updateitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <update-product-service @isValid="validate" :UpdatedItem="activeproductservice"></update-product-service>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="productserviceupdateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="success" :title="$t('update')" :loading="product_service_loading" depressed :disabled="!productserviceupdateformvalid" @click="UpdateProductService()">
                <span>{{$t('update')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- update dialog -->

        <!-- delete dialog -->
        <v-dialog v-model="productservicedeleteoverlay" width="500px">
          <v-card>
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("deleteitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="productservicedeleteoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="error" :title="$t('delete')" :loading="product_service_loading" depressed @click="DeleteProductService()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- delete dialog -->

    </v-container>
</template>
<script>
import ExpandableTextLine from 'vue-expandable-text-line';
import { mapState,mapActions,mapGetters} from "vuex";
import createproductservice from '../../components/ProductService/CreateProductService.vue';
import updateproductservice from '../../components/ProductService/UpdateProductService.vue';

export default({
    name: 'ProductServices',
    props: ['activeproduct'],
    data() {
        return { 
            temp: {},
            activeproductservice: {},
            productservicecreateoverlay: false,
            productserviceupdateoverlay: false,
            productservicedeleteoverlay: false,
            productservicecreateformvalid: false,
            productserviceupdateformvalid: false,

        }
    },
    components: { 
        'expandable-text-line':ExpandableTextLine,
        'create-product-service':createproductservice,
        'update-product-service':updateproductservice,
    },
    computed: {
        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
        ...mapGetters('product_service', {product_services:'getProductServices'}),
        ...mapState("product_service", {product_service_loading: "loading"}),
        ...mapState("file", {file_loading: "loading"}),
    },
    watch:{
        
    },
    methods: {
        validate(context,isValid){
            switch (context) {
                case 'create':
                    this.productservicecreateformvalid=isValid;
                    break;

                case 'update':
                    this.productserviceupdateformvalid=isValid;
                    break;

                default:
                    break;
            }
        },
        OpenCreateProductService() {
            this.productservicecreateoverlay = true;
            this.activeproductservice = {};
        },
        OpenUpdateProductService(item) {
            this.productserviceupdateoverlay = true;
            this.activeproductservice = {...item};
        },  
        OpenDeleteProductService(item) {
            this.productservicedeleteoverlay = true;
            this.activeproductservice = {...item};
        },  
        CreateProductService() {
            if(this.productservicecreateformvalid && this.user && this.user.permission.map(i=>i.name).includes('productservices.create')){
                this.activeproductservice.product = this.activeproduct.id;
                this.addProductService(this.activeproductservice).then(() => {
                    this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
                    this.productservicecreateoverlay = false;
                    this.activeproductservice = {};
                },() => {
                    this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                }
                );
            }
        },
        UpdateProductService() {
            if(this.productserviceupdateformvalid && this.user && this.user.permission.map(i=>i.name).includes('productservices.update')){
                this.updateProductService(this.activeproductservice).then((response) => {
                    this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
                    this.productserviceupdateoverlay = false;               
                    this.activeproductservice = {};
                    this.UpdateProductServices(response.data);
                },() => {
                    this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                }
                );
            }
        },
        UpdateProductServices(payload) {
            const edited_item_index=this.product_services.findIndex(
                (item) => item.id === payload.id
                );
            if (edited_item_index !== -1)
                this.product_services[edited_item_index] = payload;
        },
        DeleteProductService() {
            if(this.user && this.user.permission.map(i=>i.name).includes('productservices.delete')){
                this.deleteProductService(this.activeproductservice).then(() => {
                    this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
                    this.productservicedeleteoverlay = false;
                    this.activeproductservice = {};
                    },() => {
                    this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                    }
                );
            }
        },
        ClearProductService() {
            this.activeproductservice={};
        },

        ...mapActions("product_service", ["addProductService","updateProductService","deleteProductService"]),
    }
})
</script>
<style scoped>
</style>
