<template>
    <v-card
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        @click="$refs.file.click()"
        :class="{ 'custom-gray': dragover }"
        outlined
    >
        <v-card-text>
            <v-row class="d-flex flex-column" dense align="center" justify="center">
                <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="30">
                mdi-cloud-upload
                </v-icon>
                <div class="d-flex flex-column text-center">
                    <span class="">{{$t('dropfile')}}</span>
                    <span class="text-capitalize">{{$t('maxfilesize')}} {{$bytesToSize(allowedSize)}}</span>
                </div>
            </v-row>
            <v-virtual-scroll
                v-if="uploadedFiles.length > 0"
                :items="uploadedFiles"
                max-height="195"
                item-height="65">
                <template v-slot:default="{ item }">
                <v-list-item :key="item.name">
                    <v-list-item-content>
                        <v-list-item-title>
                            <span class="subtitle-1"> {{ item.name }} </span>
                            <span class="caption ml-3 text--secondary">{{ $bytesToSize(item.size) }}</span>
                        </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" @click.stop="removeFile(item.name)" :title="$t('delete')">
                              <v-icon>mdi-close-circle</v-icon>
                              </v-btn>
                          </template>
                          <span>{{$t('delete')}}</span>
                      </v-tooltip>
                    </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
                </template>
            </v-virtual-scroll>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click.stop="$refs.file.click()" :title="$t('upload')">
                    <v-icon>mdi-upload</v-icon>
                    </v-btn>
                </template>
                <span>{{$t('upload')}}</span>
            </v-tooltip>
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click.stop="clear()" :title="$t('clear')">
                    <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </template>
                <span>{{$t('clear')}}</span>
            </v-tooltip>
        </v-card-actions>
        <input type="file" ref="file" style="display: none" @change="onDrop" :accept="allowedTypes" :multiple="multiple">
    </v-card>
</template>
<script>

export default {
  props: ["allowedTypes","allowedSize","allowedLength","multiple"],
  components: {
  },
  data: () => ({
    dragover: false,
    uploadedFiles: [],
  }),
  methods: {
    removeFile(fileName) {
      // Find the index of the
      const index = this.uploadedFiles.findIndex(
        file => file.name === fileName
      );
      // If file is in uploaded files remove it
      if (index > -1){
        this.uploadedFiles.splice(index, 1);
        this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
      }
      this.upload();
    },
    onDrop(e) {
      this.dragover = false;
      let files = e.target.files || e.dataTransfer.files;
      // If there are already uploaded files remove them
      if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
      // If user has uploaded multiple files but the component is not multiple throw error
      if (!this.multiple && files.length > 1) {
          this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('filesError'), title:this.$t('warning'), type:'warn'});
      }
      // If user has uploaded too many files but the component only except a max set of files (api rescrictions usually 20 files)
      else if (this.multiple && files.length > 1 && files.length>this.allowedLength) {
          this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('fileslengthError')+this.allowedLength, title:this.$t('warning'), type:'warn'});
      }
      // Add each file to the array of uploaded files
      else
        files.forEach((element) =>{
            if(!this.allowedTypes.includes(element.type)){
                return this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('filetypeError'), title:this.$t('warning'), type:'warn'});
            }
            else if (element.size > this.allowedSize) {
                return this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('filesizeError'), title:this.$t('warning'), type:'warn'});
            }
            else{
                this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('fileuploaded'), title:this.$t('success'), type:'success'});
                return this.uploadedFiles.push(element);
            }
          }
        );
        this.upload();
    },
    clear() {
      // If there aren't any files to be uploaded throw error
      if (!this.uploadedFiles.length > 0) {
        this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('uploadclearError'), title:this.$t('warning'), type:'warn'});
      } else {
        this.uploadedFiles = [];
        // Send uploaded files to parent component
        this.upload();
        this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('filescleared'), title:this.$t('success'), type:'success'});
      }
    },
    upload() {
      this.$emit('upload',this.uploadedFiles); 
    },
  },
};
</script>

<style scoped>
</style>
